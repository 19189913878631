import * as React from "react";
import { graphql, Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout";
import bg1 from "../images/home-bg-1.png";

const IndexPage = ({ data }) => {
  const { t } = useTranslation();
  const guides = data.allMarkdownRemark.edges;
  return (
    <Layout>
      <div
        className="center pt5-l pt3-m pt1 relative home"
        style={{
          background: `transparent url('${bg1}') no-repeat right top`,
        }}
      >
        <div className="w-80-l w-90 center pv4-l pt3-m mt5-l mt3 mb5-l">
          <div className="ba w-50-l b--custom-pink pa4">
            <div className="f2 b light-gray">{t("siteTitle")}</div>
            <div className="f3 light-gray mt3">{t("siteByLine")}</div>
          </div>
        </div>
        <div className="w-80-l w-90-m w-100 guides pv5-l pv3-m pv2 ph2 cf center flex flex-wrap flex-row-ns flex-column-s">
          {guides.map((guide, idx) => {
            return (
              <Link
                to={guide.node.fields.slug}
                key={idx}
                className="card ba b--custom-pink pa3 flex flex-column items-end mh2-m mh3-l mh2 mb2 mb3-m mb3-l"
              >
                <div className="idx light-gray f1 b mb3">{idx + 1}</div>
                <div className="w-100 light-gray f4 b">
                  {guide.node.frontmatter.title}
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { lang: { eq: $language }, categories: { eq: "guide" } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            lang
          }
        }
      }
    }
  }
`;
export default IndexPage;
